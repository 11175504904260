// INDEX
// - current
// - form

// ========================================
// - current
//========================================
.current-ranking {
	margin-bottom: 40px;

	&__row {
		@include mq-pc {
			display: flex;
			align-items: center;
		}
	}

	&__theme {
		padding: 25px 40px;
		font-size: 1.1em;

		@include mq-mobile {
			padding: 20px;
		}
	}

	&__btn {
		@include mq-pc {
			margin-top: 20px;
			margin-left: -20px;
		}
	}

	.btn-special-100 {
		min-width: 200px;
		padding: 25px;
		margin-bottom: 0;
	}
}





// ========================================
// form
//========================================
.ranking__check-list {
	display: inline-block;
	padding: 10px 0;

	@include mq-mobile {
		display: block;
		border-bottom: 1px solid #ddd;
	}
}