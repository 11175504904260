/* ======================================== header ヘッダーのパーツ

    INDEX
        - common
        - sub header
        - title

======================================== */

/* ========================================
    common
======================================== */
.header {
    
}




/* ========================================
    sub header
======================================== */
.sub-header {
	position: relative;
	height: 270px;
	padding-top: 150px;
	background-color: $C_SUB1_NOT;

	@include mq-pc {
		@include container();
	}

	@include mq-mobile {
		height: auto;
		padding: 40px 10px 20px;
	}

	img {
		// width: 272px;
		max-width: 500px;

		@include mq-mobile {
			width: 100%;
			max-width: 338px;
		}
	}
}

.logged-in {
	.sub-header {
		background-color: $C_SUB2;
	}
}




// ========================================
// - title
//========================================
.page-title {
	@extend %FONT_ENG_MODERN;
	font-size: 5rem;
	line-height: 1.1em;
	letter-spacing: .0em;

	@include mq-mobile {
		font-size: 2.6rem;
	}

	&--jp {
		@extend %FONT_JP;
		font-size: 2.7em;

		@include mq-mobile {
			font-size: 1.5rem;
		}

	}
}