/* ========================================

    btn
        ボタンのスタイル

    INDEX
        - class
        - normal
        - special
        - moderate
        - none

======================================== */

// ========================================
//  class
// ========================================
// 一番普通のボタンの共通スタイル
%normal-btn {
    padding: 10px;
    color: $C_SUB1;
    text-align: center;
    background-color: #000;
    letter-spacing: .1em;
}

.logged-in {
    %normal-btn {
        color: $C_SUB2;
    }
}

// 目立たせたいボタンの共通スタイル
%special-btn {
    position: relative;
    padding-right: 40px;
    padding-left: 40px;

    &:before {
        @include after();
        top: 4px;
        bottom: 4px;
        right: 4px;
        width: 10px;
        background-color: $C_SUB1_NOT;
    }
}

.logged-in {
    %special-btn {
        &:before {
            background-color: $C_SUB2;
        }
    }
}

// 控えめボタン
%moderate-btn {
    background-color: #999;
    color: #fff;
}


// 幅いっぱいのサイズ
%btn-100 {
    display: block;
    width: 100%;
    max-width: 360px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;

    &.align-left {
        margin-left: 0;
    }
}

// ２つ並べられるサイズ
%btn-50 {
    display: inline-block;
    width: 49%;
}

// ちっちゃいサイズ
%btn-30 {
    display: block;
    width: 30%;
}


/* ========================================
    normal
        よく使うボタン
======================================== */
input[type=submit],
.btn-100 {
    @extend %normal-btn;
    @extend %btn-100;
}

.btn-50 {
    @extend %normal-btn;
    @extend %btn-50;
}

.btn-30 {
    $this: &;
    @extend %normal-btn;
    @extend %btn-30;
    margin: auto;

    // 右寄せ
    &--right {
        @extend #{$this};
        margin-right: 0;
        margin-left: auto;
    }

    // 左寄せ
    &--left {
        @extend #{$this};
        margin-right: auto;
        margin-left: 0;
    }
}


/* ========================================
    special
        特別目立たせたいボタン
======================================== */
.btn-special-100 {
    @extend %normal-btn;
    @extend %special-btn;
    @extend %btn-100;
}

.btn-special-50 {
    @extend %normal-btn;
    @extend %special-btn;
    @extend %btn-50;
}



// ========================================
//  - moderate 控えめ
//========================================  
.btn-moderate-100 {
    @extend %normal-btn;
    @extend %moderate-btn;
    @extend %btn-100;
}

.btn-moderate-50 {
    @extend %normal-btn;
    @extend %moderate-btn;
    @extend %btn-50;
}

// ========================================
//  - none
//========================================  

.btn-none {
    pointer-events: none; //ボタンイベントの打ち消し
    opacity: 0.7;
}
