/*
	いったんは必要ないのでコンパイルされないようにリネームして置いている
*/


// ========================================
//  aem scss templates
//      ver 2.0.3
// ======================================== 
@charset 'UTF-8';


/* ========================================
    common
        サイト全体の設定、定義など
======================================== */
@import "common/variables";
@import "common/mixins";
@import "common/reset";
// @import "common/normalize";
@import "common/base";
@import "common/parts";
@import "common/format_migration";
@import "common/animation";


/* ========================================
    modules
        外部プラグインなどで使うもの
======================================== */
@import "modules/accordion";
// @import "modules/bxslider_reset";
// @import "modules/card";
// @import "modules/colorbox_reset";
// @import "modules/photoswipe";
// @import "modules/share_buttons";


/* ========================================
    components
        共通で使うパーツを書くスタイル
======================================== */
@import "components/common";
@import "components/header";
@import "components/footer";
@import "components/sns";
@import "components/nav";
@import "components/support";
@import "components/pager";
@import "components/title";
@import "components/btn";
@import "components/list";
@import "components/movie";
@import "components/post-entry";
@import "components/article";
@import "components/credit";


// タブレット以上の場合のみホバーを適応
@media screen and (min-width: $BP_M) {
    @import "components/hover";
}


/* ========================================
    pages
        ページごとに独立するスタイル
======================================== */
@import "pages/index";
@import "pages/profile";
@import "pages/members";
@import "pages/regist";
@import "pages/ranking";
@import "pages/memberscard";
@import "pages/transfer";
@import "pages/gallery";
@import "pages/download";