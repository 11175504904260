/* ========================================

    footer
        フッターのパーツ

    INDEX
        - common
        - top top
        - footer sns

======================================== */

/* ========================================
    common
======================================== */
.footer {
	position: relative;
	height: 50px;

	.copy {
		width: 50%;
		padding-top: 1em;
		margin-left: auto;
		text-align: center;
		vertical-align: bottom;

		@include mq-mobile {
			clear: both;
			width: 100%;
			padding: 20px 30px;
			margin-left: 0;
			text-align: right;
			background-color: $C_SUB1_NOT;
		}
	}

	@include mq-mobile {
		height: auto;
	}
}




/* ========================================
    to top
======================================== */
.pagetop {
	position: absolute;
	top: 0;
	right: 15px;

	@include mq-mobile {
		position: static;
		float: left;
	}

	a {
		display: block;
		padding: 5px 20px;
		padding-right: 10px;
		font-family: $FONT_FAMILY_ENG;
		color: #000;
		font-style: italic;
		letter-spacing: .05em;
		font-size: 1.25em;
		border-right: 8px solid #000;

		@include mq-mobile {
			position: relative;
			padding-left: 28px;
			border-right: none;

			&:after {
				@include after();
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				height: 8px;
				width: 18px;
				background-color: #000;
			}
		}
	}
}




// ========================================
// - footer sns
//========================================
.footer__sns {
	text-align: center;
	background-color: $C_SUB1_NOT;

	a {
		display: inline-block;
		padding: 10px;
		color: #000;
		font-size: 2em;
	}
}