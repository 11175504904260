/* ========================================

    title
        見出しのパーツ

    INDEX
        - section
        - main
        - sub

======================================== */

/* ========================================
    global
        大見出し
======================================== */
.section-title {
    
}

/* ========================================
    main
        中見出し
======================================== */
.kiji_title,
.main-title {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.1em;
    letter-spacing: .1em;
    border-bottom: 1px solid #000;

    @include mq-mobile {
        font-size: 1.25rem;
    }
}

/* ========================================
    sub
        子見出し
======================================== */
.border-title,
.sub-title {
    margin-bottom: 10px;
    font-size: 1.1em;
    border-bottom: 1px solid #000;

    &.border {
        border-bottom: none;
        border-left: 10px solid black;
        padding: 10px;
        padding-left: 15px;
    }
}