.prof-name {
	position: relative;
	z-index: 10;

	@include mq-pc {
		border-bottom: 1px solid #000;
	}

	&-wrapper {
		position: relative;

		@include mq-pc {
			width: 666px;
		}

		&:after {
			@include after();
			bottom: 0;
			left: 0;
			width: 50%;
			height: 1px;
			background-color: #000;
		}		
	}

	&--jp {
		height: 24.5px;
	}

	&--eng {
		margin-left: 30px;
		height: 19.5px;
	}

	&--jp {
		@include mq-mobile {
			margin-left: 10px;
		}
	}

	&:after {
		@include mq-mobile {
			display: none;
		}
	}
}


.prof {
	display: flex;
	flex-direction: row-reverse;
	margin-bottom: 120px;

	@include mq-mobile {
		display: block;
		width: auto;
		margin-left: -20px;
		margin-right: -20px;
	}

	&__data {
		width: 500px;
		margin-left: auto;
		padding-top: 70px;
		padding-right: 30px;

		@include mq-mobile {
			width: 100%;
			padding-top: 0;
			padding-left: 40px;
			padding-right: 20px;
		}
	}

	&__img {
		width: 50%;
		margin-top: -20px;

		@include mq-mobile {
			width: 100%;
			margin-bottom: 20px;
			padding-right: 40px;
		}

		img {
			width: 100%;
			max-width: 720px;
		}
	}
}