/* ========================================

    index
        indexページのみで使うスタイル

    INDEX
    	- common
        - top header
        - main visual
        - top news
        - top banner
        - top schedule
        	- top-recent-schedule
        - top-members
        - footer

======================================== */


// ========================================
// - common
//========================================
%top-container {
	@include mq-mobile {
		padding-right: 20px;
		padding-left: 20px;
		background-color: #fff;
	}

	.list {
		@include mq-mobile {
			margin-right: 0;
			margin-left: 0;
		}
	}
}



/* ========================================
    top header
======================================== */
.top-header {
	position: relative;
	background-color: $C_SUB1_NOT;

	@include mq-mobile {
		overflow: hidden;
	}

	&__logo {
		position: absolute;
		z-index: 1;

		@include mq-pc {
			margin-left: -520px;
			left: 50%;
			top: 0;
		}

		@include mq-mobile {
			top: 0;
			left: 0;
			width: 120px;
			z-index: 10;
		}
	}
}

.logged-in {
	.top-header {
		background: url('/images/common/bg/bg_blue.png');
		background-size: 100px;
	}
}

.top-join {
	position: absolute;
	height: 100px;
	width: 100px;
	color: #fff;
	background-color: #000;
	border-radius: 100px;

	@include mq-pc {
		top: 80px;
		left: 50%;
		margin-left: 350px;
	}

	@include mq-mobile {
		top: 20px;
		right: 20px;
		width: 50px;
		height: 50px;
		z-index: 10;
	}

	&__text {
		@include center();
		@extend %FONT_ENG;
		display: block;
		width: 100%;
		height: 100%;
		border-radius: 100px;
		color: #fff;
		font-size: 1.4em;
		letter-spacing: .2em;
		text-align: center;
		line-height: 100px;

		@include mq-mobile {
			line-height: 50px;
			font-size: .75rem;
		}
	}
}



/* ========================================
    main visual
======================================== */
.top__main-visual {
	@extend .container;

	@include mq-mobile {
		position: relative;
		z-index: 5;
		padding-top: 50px;
		padding-left: 20px;
	}

	img {
		@include mq-mobile {
			max-width: 150%;
			width: 150%;
		}
	}
}




// ========================================
// - top news
//========================================
.top-news {
	@extend %top-container;
	background-color: #fff;

	@include mq-pc {
		position: absolute;
		top: 40%;
		left: 50%;
		width: 50%;
		transform: translateY(-50%);
	}

	@include mq-mobile {
		position: relative;
	}

	&:after {
		@include mq-mobile {
			@include after();
			top: 0;
			left: 40px;
			width: 120px;
			bottom: 40px;
			background-color: $C_SUB1_NOT;
		}
	}

	.list {
		position: relative;
		z-index: 1;
		
		@include mq-pc {
			max-width: 460px;
			margin-left: 40px;
		}

		@include mq-mobile {
			margin-right: 0;
			margin-left: 0;
			margin-bottom: 0;
		}
	}

	.section-title {
		@include mq-pc {
			margin-top: -50px;
			padding-left: 32px;
		}

		@include mq-mobile {
			position: relative;
			z-index: 5;
		}

		img {
			max-width: 500px;

			@include mq-mobile {
				max-width: 338px;
			}
		}
	}

	&__more {
		@include mq-pc {
			position: absolute;
			left: 40px;
			top: 20px;
			bottom: -100px;
			width: 144px;
			background-color: $C_SUB2_NOT;
		}

		.more {
			@extend %FONT_ENG;
			width: 100%;
			display: block;
			font-size: 1.25em;
			// font-weight: 400;
			line-height: 100px;
			text-align: center;
			color: #000;
			transition: all 0.3s ease-in-out;

			@include mq-pc {
				position: absolute;
				left: 0;
				bottom: 0;
				height: 100px;
			}

			@include mq-mobile {
				height: 60px;
				line-height: 60px;
			}

			&:hover {
				background-color: #000;
				color: $C_SUB1_NOT;
			}
		}
	}
}

.logged-in {
	.top-news__more {
		background: url('/images/common/bg/bg_pink.png');
		background-size: 60px;
	}
}


// ========================================
// - top banner
//========================================
.top-banner {
	position: relative;
	text-align: right;
	padding: 10px 0;

	&-inner {
		@include mq-pc {
			position: relative;
			min-width: 740px;
			width: 740px;
			height: 180px;
			overflow: hidden;
			margin-right: 125px;
			margin-left: auto;
		}
	}
	
	a {
		display: block;
		vertical-align: bottom;
	}

	.bx-pager {
		position: absolute;
		right: 10px;
		bottom: 10px;
		width: auto;
	}

	.bx-wrapper .bx-pager.bx-default-pager a {
		width: 10px;
		height: 10px;
		border-radius: 0;
		opacity: .5;
	}
}



// ========================================
// - top schedule
//========================================
.top-schedule {
	@extend %top-container;
	position: relative;
	padding-top: 40px;
	padding-bottom: 40px;
	background: url('/images/common/bg/stripe_blue_trans.png');

	@include mq-mobile {
		background-color: $C_SUB1_NOT;
	}

	&:after {
		@include after();
		top: 0;
		right: 0;
		bottom: 0;
		width: 15px;
		background-color: $C_SUB1_NOT;
	}

	&__container {
		max-width: 600px;
		margin-left: auto;

		@include mq-pc {
			margin-right: 130px;
		}
	}

	.section-title {
		text-align: right;

		img {
			height: 40px;

			@include mq-mobile {
				height: 30px;
			}
		}
	}

	&-list {
		margin-bottom: 0;
	}

	&__more {
		@include mq-pc {
			text-align: right;
		}

		.more {
			@extend %FONT_ENG;
			padding: 10px 20px;
			font-size: 1.25em;;
			line-height: 100px;
			text-align: center;
			color: #000;
			transition: all 0.3s ease-in-out;

			@include mq-pc {
				display: inline-block;
			}

			@include mq-mobile {
				display: block;
				height: 60px;
				line-height: 60px;
				padding: 0;
			}
		}
	}
}

.logged-in {
	.top-schedule {
		background: url('/images/common/bg/stripe_pink_trans.png');
	}
	.top-schedule:after {
		background: url('/images/common/bg/bg_blue.png');
		background-size: 100px;
	}
}


// ========================================
// - top-recent-schedule
//========================================
.top-recent-schedule {
	position: relative;
	margin-top: 40px;
	margin-bottom: 10px;
	padding-top: 0;
	border: 1px solid #000;
	background-color: #fff;

	@include mq-mobile {
		background-color: #fff;
	}

	&__link {
		display: block;
		padding: 40px;
		color: #000;

		@include mq-mobile {
			padding-top: 20px;
		}
	}

	&__time {
		position: absolute;
		top: -20px;
		left: -40px;
		display: inline-block;
		padding: 5px 20px;
		color: #fff;
		font-size: 2em;
		line-height: 1.25em;
		background-color: #000;

		@include mq-mobile {
			font-size: 1.25em;
			left: -10px;
		}
	}

	&__title {
		font-size: 1.25em;
	}

	.more {
		@extend %FONT_ENG;
		position: absolute;
		bottom: 5px;
		right: 20px;
		font-size: 1.25em;
	}
}





// ========================================
// - top-members
//========================================
.top-members {
	@extend %top-container;
	overflow: hidden;
	position: relative;

	@include mq-pc {
		position: relative;
	}

	&:before {
		@include mq-pc {
			@include after();
			right: 0;
			top: 0;
			bottom: 0;
			width: 70%;
			background-color: $C_SUB1_NOT;
		}
	}

	&:after {
		@include after();
		right: 0;
		top: 0;
		bottom: 0;
		width: 15px;
		background-color: #fff;
	}

	.container {
		@include mq-mobile {
			display: flex;
			flex-direction: row-reverse;
		}
	}

	&__menu {
		position: relative;
		z-index: 10;
		width: 140px;
		margin: 0 auto;
		background-color: #000;

		@include mq-mobile {
			margin-right: -20px;
			width: 100px;
		}

		&-wrapper {
			@include mq-mobile {
				display: flex;
				flex-direction: row-reverse;
			}
		}
	}

	.section-title {
		@include mq-pc {
			position: absolute;
			left: 50%;
			top: 50px;
			z-index: 5;
			margin-left: -650px;
		}

		@include mq-mobile {
			position: relative;
			padding-top: 40px;
			margin-bottom: 40px;
		}

		img {
			max-width: 500px;

			@include mq-mobile {
				position: absolute;
				left: 20px;
				top: 0;
				max-width: 100%;
				width: 338px;
			}
		}
	}

	&__row {
		@include mq-mobile {
			width: 100%;
			padding-top: 100px;
			margin-left: -20px;
		}
	}

	&__photo {
		@include mq-pc {
			position: absolute;
			left: 50%;
			top: 100px;
			transform: translateX(-100%);
		}
	}

	&__text {
		@include mq-pc {
			position: absolute;
			left: 50%;
			top: 170px;
			width: 400px;
			margin-left: 100px;
		}

		@include mq-mobile {
			position: relative;
			z-index: 2;
			padding-left: 20px;
			padding-right: 10px;
			margin-bottom: 0;
			line-height: 1.5em;
		}
	}

	// hoverで出てくる方
	&-menu {
		&__text {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			width: 100%;
			transform: translateX(-50%);
			z-index: 1;

			@include mq-mobile {
				z-index: 5;
			}
		}

		&__item {
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 50%;
			width: 0;
			height: 50px;
			transition: width 0.25s ease-in-out;

			@include mq-pc {
				transform: translateX(-50%);
			}

			@include mq-mobile {
				left: auto;
				right: 0;
				z-index: 1;
			}

			&.active {
				width: 100%;

			}
		}

		&__desc {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 50%;
			left: 0;
			height: 100%;
			min-width: 40em;
			color: #fff;
			background-color: #000;

			@include mq-mobile {
				right: 0;
				min-width: 0;
			}

			&-text {
				position: absolute;
				top: 50%;
				left: 15px;
				transform: translateY(-50%);
			}
		}

		&__update {
			@include mq-pc {
				display: flex;
			}

			@include mq-mobile {
				font-size: .92em;
				line-height: 1.25em;
			}

			&-text {
				@extend %FONT_ENG;
				margin-right: 0.5em;
				color: $C_SUB1_NOT;
			}

			&-title {
				@include mq-mobile {
					padding-right: 100px;
				}
			}

			time {
				margin-right: 10px;
			}
		}

		&__img {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			right: 0;
			height: 100%;
			// width: 50%;
			background: #dde9f5 url('/images/top/menu/bg_01.jpg') no-repeat center left;

			&--2 {
				background-image: url('/images/top/menu/bg_02.jpg');
			} 

			&--3 {
				background-image: url('/images/top/menu/bg_03.jpg');
			} 

			@include mq-mobile {
				display: none;
			}
		}
	}

	&__content {
		&-title {
			@extend %FONT_ENG;
			letter-spacing: .1em;
		}
	}
}

.logged-in {
	.top-members:before {
		background: url('/images/common/bg/bg_blue.png');
		background-size: 100px;
	}
}


// ========================================
// - footer
//========================================

.top + .footer {
	.pagetop {
		@include mq-mobile {
			margin-top: -39px;
		}
	}
}
