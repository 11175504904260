/* ========================================

    pager
        ページャーのパーツ

    INDEX
        - pager
        - back to list

======================================== */

/* ========================================
    pager
======================================== */
.pager {
    @extend %FONT_ENG_MODERN;
    color: #000;
    text-align: center;
    font-size: 2em;

    &__current {
        display: inline-block;
        padding: 10px 5px;
        color: $C_SUB3;
    }

    a {
        display: inline-block;
        color: #000;
        padding: 10px 5px;
    }

    // 戻る
    .btn-back {

    }

    // 一覧へ
    .btn-list {

    }
    
    // 次へ
    .btn-next {

    }

    // 番号付きページング
    .btn-num {
        &__item {

            &.current {

            }
        }
    }

    // ボタンの打ち消し
    .btn-none {
        pointer-events: none;
    }
}




/* ========================================
    back to list
======================================== */
.link-to-list {
    @extend %FONT_ENG;
    $_size: 108px;

    position: relative;
    display: inline-block;
    width: $_size;
    height: $_size;
    font-size: 1.25em;
    line-height: 1.5em;
    color: #fff;
    border: 0 solid #000;
    border-radius: $_size;
    background-color: #000;
    transform: scale(0) translateY(-50%);
    transition: transform 0.4s ease-in-out;
    transform-origin: top center;

    @include mq-pc() {
        position: absolute;
        // top: 78px;
        right: 0;
        margin-left: 140px;
    }

    @include mq-mobile {
        $_sp_size: 60px;
        position: absolute;
        // top: 40px;
        right: -10px;
        width: $_sp_size;
        height: $_sp_size;
        font-size: .83em;
        line-height: 1.25em;
    }

    &:before {
        @include after;
        @include center();
        width: $_size;
        height: $_size;
        border-radius: $_size;
        background-color: #000;
        transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        @include mq-mobile {
            display: none;
        }
    }

    &:hover {
        // color: #000;
        // background-color: #fff;

        &:before {
            transform: translate(-50%, -50%) scale(1.1);
        }
    }

    &__text {
        @include center();
        display: inline-block;
        width: 100%;
        text-align: center;
        font-weight: 700;
        letter-spacing: .1em;
    }
}