// INDEX
// - members-summary
// - members menus
// - members detail
// - members contents
// - members desc
// - members join


.members {
	@include mq-mobile {
		margin-right: -20px;
		margin-left: -20px;
	}
}


// ========================================
// members summary
//========================================
.members-summary {
	position: relative;
	margin-top: -48px;

	&:after {
		@include after();
		top: 0;
		left: 50%;
		right: 0;
		height: 100%;
		margin-left: 500px;
		background-color: $C_SUB3_bg;
	}

	.container {
		position: relative;
		z-index: 2;
		display: flex;
		background-color: #fff;

		@include mq-mobile {
			flex-direction: row-reverse;
		}
	}
}

.logged-in{
	.members-summary {
		&:after {
			background-color: $C_SUB3_bg;
		}
	}
}



// ========================================
// members menus
//========================================
.members__menus {

	@include mq-pc {
		width: 330px;
		margin-top: -52px;
	}

	.float-menu {
		margin-right: 0;
		margin-left: auto;

		@include mq-mobile {
			width: 80px;
		}
	}
}




// ========================================
// members detail
//========================================
.members__detail {

	@include mq-pc() {
		width: 670px;
		padding-left: 100px;
		margin-top: -128px;
	}

	&-photo {
		margin-bottom: 20px;

		@include mq-pc {
			margin-bottom: 75px;
		}
	}

	&-text {
		@include mq-mobile {
			padding-left: 40px;
			padding-right: 10px;
		}
	}
}




// ========================================
// - members contents
//========================================
.members-contents {
	// max-width: 680px;
	position: relative;
	z-index: 10;
	padding-bottom: 80px;
	padding-top: 40px;

	@include mq-mobile {
		padding-right: 50px;
		padding-left: 20px;
	}

	&:before {
		@include after();
		left: 0;
		top: 0;
		width: 60%;
		height: 100%;
		background: url("/images/common/bg/stripe_pink_trans.png");
		border-top: 20px solid $C_SUB1_NOT;

		@include mq-mobile {
			width: 80%;
		}
	}

	&:after {
		@include after();
		right: 0;
		left: 50%;
		top: 0;
		height: 100%;
		margin-left: 500px;
		background-color: $C_SUB3_bg;
	}

	.container {
		position: relative;

		@include mq-pc() {
			padding-right: 300px;
		}
	}

	&-list {
		&__item {
			align-items: center;
			padding: 20px 0 5px 5px;
			font-feature-settings: "palt";
			letter-spacing: .05em;
			border-bottom: 1px solid #000;

			@include mq-pc {
				display: flex;
			}
		}

		&__term {
			@extend %FONT_ENG;
			font-weight: 500;
			font-size: 1.5em;

			@include mq-pc {
				width: 170px;
			}
		}
	}
}

.logged-in {
	.members-contents {
		&:before {
			background: url("/images/common/bg/stripe_blue_trans.png");
			border-top: 20px solid $C_SUB3_bg;
		}

		&:after {
			background-color: $C_SUB3_bg;
		}
	}
}




// ========================================
// - members desc
//========================================
.members-desc {
	position: relative;
	z-index: 5;

	&:before {
		@include after();
		right: 0;
		left: 50%;
		height: 100%;
		background-color: $C_SUB3_bg;
	}

	&__price {
		font-size: 2em;
	}

	&__inner {
		padding-left: 60px;
		padding-top: 130px;
		padding-bottom: 40px;
		margin-left: auto;
		margin-top: -100px;
		background-color: $C_SUB3_bg;

		@include mq-pc {
			width: 680px;
		}
	}
}

.logged-in {
	.members-desc {
		&:before {
			background-color: $C_SUB3_bg;
		}

		&__inner {
			background-color: $C_SUB3_bg;
		}
	}
}




// ========================================
// - members join
//========================================
.members-join {
	position: relative;
	
	@include mq-pc {
		margin-top: -150px;
	}

	.section-title {
		@include mq-mobile {
			padding-left: 10px;
		}
	}

	&__photo {
		@include mq-pc {
			width: 60%;
		}

		img {
			width: 100%;
		}
	}

	&__detail {
		@include mq-pc {
			position: absolute;
			left: 50%;
			top: 200px;
			width: 500px;
			z-index: 1;
		}
	}

	&-list {

		@include mq-mobile {
			padding-left: 60px;
			padding-right: 20px;
		}

		&__item {
			margin-bottom: 20px;
		}

		&__btn {
			max-width: 100%;
		}
	}
}