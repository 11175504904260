/* ========================================

    movie
        動画関係のパーツ

    INDEX
        - youtube
        - milvi

======================================== */

/* ========================================
    youtube
======================================== */
// iframeで埋め込むyoutubeをレスポンシブ化できる
.movie-wrappaer {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}


/* ========================================
    milvi
======================================== */
// ミルビー動画を画面からはみ出さないようにする

// ----------------------------------------
// 例）
//     <div class="movie-container">
//         <script type="text/javascript">var Eviry=Eviry||{};Eviry.Player||(Eviry.Player={});Eviry.Player.embedkey="EK124KrW_Kk-6y3ZRcC90lOyxs6NBDGuULTIZh_SJsSB5wlDNRCBW-ai5U_JJXNjPEeJO9stHrsaUHq9vM5hSb5NiUH89de5hgSQ7dXWShyyv-hp-DuHmeT5A..";</script>
//         <script type="text/javascript" src="https://d1euehvbqdc1n9.cloudfront.net/001/eviry/js/eviry.player.min.js"></script>
//     </div>
// ----------------------------------------

// ミルビータグを囲うdivにつける
.movie-container {
    position: relative;
    padding-top: 56.25%;
}

// ミルビータグから書き出されるdivに付いているもの
.eviry-screen {
    max-width: 100%;
    max-height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
}

// ミルビータグから書き出されるdivに付いているもの
.eviry-baseplayer {
    position: absolute !important;
    max-width: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
}

// 音声だけのミルビー
.eviry-baseplayer,
.eviry-deck,
.miovip-controlpanel {
    margin: 0 auto !important;
    width: 100% !important;
}
