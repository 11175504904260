/* ========================================
  
    hover
        ホバーの動きはここに定義して、
        %始まりのクラスをextendsして使いたい

    INDEX
        - opacity

======================================== */

/* ========================================
    opacity
======================================== */
%h-opa {
    transition: opacity 0.5s;
    opacity: 1;

    &:hover {
        opacity: 0.6;
    }
}
