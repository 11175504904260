/* ========================================

    support
        サポートのパーツ

    INDEX
        - area
        - list

======================================== */

/* ========================================
    area
======================================== */
body,
.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.support-area {
    background-color: #000;

    @include mq-pc() {
        @include container();
        margin-top: auto;
    }

}



/* ========================================
    list
======================================== */
.support-list {
	text-align: center;
	overflow: hidden;

    @include mq-pc {
        padding: 50px 0 25px;
    }

	@include mq-mobile() {
		display: none;
	}

    &__item {
    	display: block;

        @include mq-pc {
            width: 16.666666666666666%;
            float: left;
            font-size: .83em;
        }

    	@include mq-mobile() {
    		float: left;
    		width: 50%;
    		font-size: .83em;
	    }
	}

    a {
    	display: block;
    	padding: 10px 5px;
    	color: #fff;

    	@include mq-mobile() {
    		padding-left: 5px;
    		padding-right: 5px;
    	}
    }
}


.support-toggle {
	display: none;
	text-align: center;
	font-family: $FONT_FAMILY_ENG;
	font-style: italic;
	letter-spacing: .04em;
	color: #fff;
	padding: 20px;

	@include mq-mobile() {
		display: block;
	}
}