/* ========================================

    regist
        registページのみで使うスタイル

    INDEX
        - icon list
        - credit list
        - career list
        - career logo

======================================== */

/* ========================================
   icon list
======================================== */
// 会員登録画面のアイコン
.regist-icon-list {
	@include clearfix;
	@include mq-pc() {
        text-align: left;
    }
    margin: 20px 0;
    text-align: center;
	font-size: 0px;
	max-width: 550px;

    &__item {
        display: inline-block;
        width: 22%;
        height: 80px;
        font-size: 10px;
        text-align: center;
        line-height: 1.4;
        border: 2px solid $C_SUB3;
        //background-color: $C_BLUE;

        &:not(:first-child) {
            margin-left: 1.5%;
        }

        a {
            display: table;
            width: 100%;
            height: 100%;
            color: $C_BLACK;

            span {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
            }

            .cherry {
                width: 12px;
                padding-bottom: 4px;
            }
        }
    }
}


/* ========================================
   credit list
======================================== */
// クレジットカードの表
.credit-list {

    &__item {
        display: inline-block;
        //margin-bottom: 5px;
        width: 40px;

        img {
            vertical-align: middle;
        }
    }
}


/* ========================================
   career list
======================================== */
// キャリア決済の表
.career-list {
    &__item {

    }
}


/* ========================================
   career logo
======================================== */ 
// auのロゴ
.a-logo {
    vertical-align: middle;
    width: 140px;
    padding: 3px 6px;
    background-color: $C_WHITE;
}

// ドコモ払いのロゴ
.d-logo {
    width: 60px;
    background-color: $C_WHITE;
    padding: 3px 6px;
    vertical-align: middle;
}

// docomoのボタン
.d-btn {
    width: 150px;
}