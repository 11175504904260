/* ========================================

    nav
        ナビゲーションのパーツ

    INDEX
        - global
        - trigger
        - sub
        - pnkz
        - members menu
        - float menu
        - global float menu
        - global float menu sp

======================================== */

/* ========================================
    global
======================================== */
@keyframes active_menu_mark {
    from {
        height: 0;
    }

    to {
        height: 8px;
    }
}

.global-menu {
    position: absolute;
    right: 60px;
    top: 0;
    z-index: 10;
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-size: 1.5em;
    letter-spacing: .08em;
    color: #000;

    @include mq-mobile {
        right: 0;
        width: 100%;
        z-index: 100;
        font-size: 1.25em;
    }

    &__item {
        a {
            display: block;
            padding: 20px;
            color: #000;

            @include mq-mobile {
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: right;
            }
        }

        &.active {
            @include mq-pc {
                position: relative;
            }

            &:after {
                @include mq-pc {
                    @include after();
                    top: 0;
                    left: 50%;
                    height: 0;
                    width: 32px;
                    background-color: #000;
                    transform: translateX(-50%);
                    animation: active_menu_mark 0.4s 1s ease-in-out;
                    animation-fill-mode: forwards;
                }
            }
        }

        &--members {
            display: none;

            @include mq-mobile {
                display: block;
            }

            img {
                width: 16.5px;
                vertical-align: middle;
            }

            a {
                color: $C_SUB1_NOT;
                background-color: #000;

                @include mq-mobile {
                    padding-left: 0;
                }
            }
        }
    }


    &-toggle {
        @extend %FONT_ENG;
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        padding: 5px 10px 10px;
        color: #000;

        &:after {
            @include after();
            top: 0;
            left: 50%;
            width: 32px;
            height: 8px;
            background-color: #000;
            transform: translateX(-50%);
        }
    }

    &-close {
        @extend %FONT_ENG;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 8px;
        line-height: 1.4em;
        border-left: 8px solid #000;

        a {
            display: block;
            color: #000;
        }
    }

    &__sns-item {
        padding: 10px 0;

        a {
            display: block;
            padding: 10px;
            color: #000;
        }
    }
}



.global-nav {
    display: flex;

    @include mq-mobile {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        width: 60%;
        height: 100%;
        min-height: 480px;
        z-index: 10000;
        overflow: hidden;
        border-left: 8px solid #000;
        background-color: $C_SUB1_NOT;
        transition: all 0.3s ease-in-out;
        // transform: translate(10px, -100%) rotate(-10deg);
        transform: translate(100%, 0);
        transform-origin: left top;
        transition-delay: 0.2s;

        &:after {
            @include after();
            display: none;
            top: 0;
            left: -10%;
            width: 120%;
            height: 100%;
            background-color: #000;
            transform: rotate(2deg);
            transition: all 0.4s ease-in-out;
            transition-delay: 0s;
        }
    }

    &.loaded {
        &:after {
            display: block;
        }
    }

    &.active {
        @include mq-mobile {
            // transform: translate(0, 0%) rotate(0deg);
            transform: translate(0, 0);
            transition-delay: 0s;

            &:after {
                width: 0;
                transition-delay: 0.2s;
            }
        }
    }
}

// android4のメニュー対応
.ua-android4 {
    .global-nav {
        transition-delay: 0s;

        &:after {
            @include mq-mobile {
                display: none;
            }
        }
    }
}


/* ========================================
    trigger
======================================== */
// 以下から持ってきたハンバーガーメニュー
// https://www.nxworld.net/tips/12-css-hamburger-menu-active-effect.html
%trigger-base {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
}

.trigger-menu {
    @extend %trigger-base;
    position: relative;
    width: 50px;
    height: 44px;

    span {
        @extend %trigger-base;
        position: absolute;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: white;
        border-radius: 4px;

        &:nth-of-type(1) {
            top: 0;
        }
          
        &:nth-of-type(2) {
            top: 20px;
        }
          
        &:nth-of-type(3) {
            bottom: 0;
        }
    }

    &.active {
        span {
            &:nth-of-type(1) {
                transform: translateY(20px) rotate(-45deg);
            }
            
            &:nth-of-type(2) {
                opacity: 0;
            }
            
            &:nth-of-type(3) {
                transform: translateY(-20px) rotate(45deg);
            }
        }
    }
}


/* ========================================
    sub
======================================== */
.sub-menu {
    
}


/* ========================================
    pnkz
======================================== */
.pnkz {
    
}

/* ========================================
    members menu
======================================== */
.members-menu {

}




// ========================================
// - float menu
//========================================
.float-menu {
    padding: 80px 0;
    width: 140px;
    text-align: center;
    color: $C_SUB1_NOT;
    background-color: #000;

    @include mq-mobile {
        padding: 40px 0;
        max-width: 100px;
    }

    .login-menu {
        // @extend %FONT_ENG;
        margin-bottom: 40px;
        font-size: 1.25em;
        font-style: italic;
        letter-spacing: .1em;
    }

    a {
        display: block;
        height: 50px;
        padding: 0 10px;
        color: $C_SUB1_NOT;
        line-height: 50px;

        @include mq-mobile {
            padding-right: 0;
            padding-left: 0;
            font-size: .92rem;
        }

        img {
            vertical-align: middle;
        }
    }

    .icon-menu {

        img {
            width: 33px;
        }

        &__item {
            a {
                display: block;
                position: relative;

                &:after {
                    @include after();
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    background-color: $C_SUB2_NOT;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 33px;
                    transition: opacity 0.2s ease-in-out;
                }

                &.hover,
                &.active {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        &__link {
            &--gallery {
                &:after {
                    background-image: url("/images/common/icons/icon_gallery_black.png");
                }
            }

            &--movie {
                &:after {
                    background-image: url("/images/common/icons/icon_movie_black.png");
                }
            }

            &--ranking {
                &:after {
                    background-image: url("/images/common/icons/icon_ranking_black.png");
                }
            }

            &--birthday {
                &:after {
                    background-image: url("/images/common/icons/icon_birthday_black.png");
                }
            }

            &--members_card {
                &:after {
                    background-image: url("/images/common/icons/icon_card_black.png");
                }
            }

            &--mail {
                &:after {
                    background-image: url("/images/common/icons/icon_mail_black.png");
                }
            }

            &--download {
                &:after {
                    background-image: url("/images/common/icons/icon_download_black.png");
                }
            }
        }
    }
}

.logged-in {
    .float-menu a {
        color: $C_SUB2_NOT;
    }

    .float-menu .icon-menu__item a:after {
        background-color: $C_SUB4_NOT;
    }
}




// ========================================
// - global float menu
//========================================
.global-content-menu {
    position: absolute;
    top: 100px;
    right: 0;
    z-index: 5;

    @include mq-pc {
        transition: transform 0.4s ease-in-out;
        // transition-delay: 0.2s;
    }

    @include mq-mobile {
        display: none;
    }

    .float-menu {
        max-width: 80px;
    }
}





// ========================================
// - global float menu sp
//========================================
.global-menu__content {
    display: none;
    position: absolute;
    top: 70px;
    bottom: 0;
    left: 0;
    width: 60px;
    background-color: #000;

    @include mq-mobile {
        display: block;
    }

    .float-menu {
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
}


