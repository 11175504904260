// ========================================
//
//      mixins
//          何度も使う重複する記述を定義
//    
//      INDEX
//          - float
//          - after
//          - media query
//          - center
//
// ========================================

// ========================================
//    float
// ========================================
// floatの解除
@mixin clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



// ========================================
//  after
//========================================
@mixin after() {
    content: "";
    display: block;
    position: absolute;
}



// ========================================
//  center
//========================================
@mixin center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}




// ========================================
//    media query
// ========================================

// ----------------------------------------
// ブラウザ幅に応じて反映するスタイルを変えるmixin
// これを使えばあちこちに同じクラス名を書かなくて済む
//
// ＜前提＞
//  $BP_L: 1000px;
//  $BP_M: 750px;
//  $BP_S; 480px;
//
// ＜例＞
//  .box {
//      // 全幅で効くスタイル
//
//      @include mq-xl() {
//          // "1000px以上"の時に効くスタイル
//      }
//     
//      @include mq-l() {
//          // "750px 〜 1000px"の時に効くスタイル
//      }
//
//      @include mq-m() {
//          // "480px 〜 750px"の時に効くスタイル
//      }
//
//      @include mq-s() {
//          // "480px以下"の時に効くスタイル
//      }
//
//
//      /**
//       * 引数に"false"を渡すと"それ以外"となる
//       */
//      @include mq-xl(false) {
//          // "1000px以上以外"の時に効くスタイル
//      }
//     
//      @include mq-l(false) {
//          // "750px 〜 1000px以外"の時に効くスタイル
//      }
//
//      @include mq-m(false) {
//          // "480px 〜 750px以外"の時に効くスタイル
//      }
//
//      @include mq-s(false) {
//          // "480px以下以外"の時に効くスタイル
//      }
//
//
//      /**
//       * TBを基準にそれ以下、それ以上で２分できる
//       */
//      @include mq-mobile() {
//          // "750px以下"の時に効くスタイル
//      }
//
//      @include mq-pc() {
//          // "750px以上"の時に効くスタイル
//      }
//  }
// ----------------------------------------

//
// PC幅以上 のみ有効なスタイル
//      $is_pc  true  => PC幅のみ
//              false => PC幅以外
//
@mixin mq-xl($is_pc: true) {
    @if $is_pc {
        // デフォルト。PC幅以上
        @media screen and (min-width: $BP_L) {
            @content;
        }
    } @else {
        // TB幅以下
        @media screen and (max-width: $BP_L - 1) {
            @content;
        }
    }
}

// 
// TB 〜 PC幅 のみ有効なスタイル
//      $is_pc  true  => PC幅のみ
//              false => PC幅以外
//
@mixin mq-l($is_pc: true) {
    @if $is_pc {
        // デフォルト。TB 〜 PC幅のみ有効
        @media screen and (min-width: $BP_M) and (max-width: $BP_L) {
            @content;
        }
    } @else {
        // PC幅以外
        @media not screen and (min-width: $BP_M) and (max-width: $BP_L) {
            @content;
        }
    }
}

//
// SP 〜 TB幅 のみ有効なスタイル
//      $is_tb  true  => TB幅のみ
//              false => TB幅以外
//
@mixin mq-m($is_tb: true) {
    @if $is_tb {
        // デフォルト。SP 〜 TB幅のみ有効
        @media screen and (min-width: $BP_S + 1) and (max-width: $BP_M) {
            @content;
        }
    } @else {
        // TB幅以外
        @media not screen and (min-width: $BP_S + 1) and (max-width: $BP_M) {
            @content;
        }
    }
}

//
// SP幅以下 のみ有効なスタイル
//      $is_sp  true  => SP幅のみ
//              false => SP幅以外
//
@mixin mq-s($is_sp: true) {
    @if $is_sp {
        // 初期値。SP幅のみ
        @media screen and (max-width: $BP_S) {
            @content;
        }
    } @else {
        // SP幅以外
        @media not screen and (max-width: $BP_S) {
            @content;
        }
    }
}

//
// TB, SPのみ
//
@mixin mq-mobile() {
    @media screen and (max-width: $BP_M) {
        @content;
    }
}

//
// PC以上のみ
//
@mixin mq-pc() {
    @media screen and (min-width: $BP_M + 1) {
        @content;
    }
}