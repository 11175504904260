button.mfp-arrow {
	$_size: 40px;
	height: $_size;
	width: $_size;
	border-radius: $_size;
	background-color: #000;
	opacity: 1;

	@include mq-mobile {
		margin-top: -20px;
	}

	&:active {
		@include mq-mobile {
			margin-top: -20px;
		}
	}

	&:before {
		display: none;
	}

	&:after {
		@include center();
		margin: 0;
		margin-left: -5px;
	}

	&-right {
		@include mq-pc {
			left: 50%;
			margin-left: 270px;
		}

		@include mq-mobile {
			right: 10px;
		}

		&:after {
			margin-left: 5px;
		}
	}

	&-left {
		@include mq-pc {
			left: 50%;
			margin-left: -320px;
		}

		@include mq-mobile {
			left: 10px;
		}
	}
}
