/* ========================================
  
    list
        リストのスタイル

    INDEX
        - text list
        - thumb list
        - circle list
        - gallery list
        - category list

======================================== */

// ========================================
//  text list
//      テキストのみリスト
// ========================================
.list {
    $this: &;
    display: block;
    max-width: 666px;
    margin-bottom: 45px;

    &__item {
        $item: &;
        word-break: break-word;
        font-size: 16px;

        &-link {
            display: block;
            padding-bottom: 15px;
            padding-top: 15px;
            color: #000;
            border-bottom: 1px solid #000;
        }

        &-update {
            @extend %FONT_ENG;
            float: right;
            font-size: .66em;
            margin-top: .25em;
        }

        &-category {
            display: inline-block;
            padding: 0 10px;
            font-size: .75em;
            color: #fff;
            line-height: 1.25em;
            background-color: #000;
        }

        &--2 {
            margin-bottom: 30px;
            font-size: 1.25em;

            #{$item}-link {
                padding: 10px 20px;
                border-bottom: none;
                border-left: 10px solid #000;
            }
        }
    }

    // ========================================
    //  thumb list
    //      サムネありリスト
    // ========================================
    &--thumb {
        @extend #{$this};
        overflow: hidden;

        #{$this}__item {
            $item: #{$this}__item;

            &:nth-child(even) {
                #{$item}-link {
                    flex-direction: row-reverse;

                    &:before {
                        transform: translateX(-100%);
                    }
                }

                #{$item}-data {
                    text-align: right;

                    @include mq-pc {
                        padding-right: 30px;
                    }

                    @include mq-mobile {
                        padding-right: 5px;
                    }
                }
            }

            &:nth-child(odd) {
                #{$item}-data {
                    @include mq-pc {
                        padding-left: 30px;
                    }

                    @include mq-mobile {
                        padding-left: 5px;
                    }
                }
            }

            &-link {
                position: relative;
                display: flex;
                padding-top: 0;
                padding-bottom: 0;
                border-bottom: none;

                &:before {
                    @include after();
                    height: 60px;
                    width: 80px;
                    top: 40px;
                    left: 50%;
                    background-color: $C_SUB1_NOT;

                    @include mq-mobile {
                        top: 10px;
                        height: 40px;
                    }
                }
            }

            &-thumb {
                width: 50%;

                img {
                    width: 100%;
                }
            }

            &-data {
                position: relative;
                width: 50%;

                @include mq-pc {
                    padding-top: 40px;
                }

                @include mq-mobile {
                    padding-top: 25px;
                    font-size: .83em;
                    line-height: 1.25em;
                }

                time {
                    @include mq-pc {
                        display: block;
                        margin: 20px 0;
                    }
                }
            }
        }
    }

    // ========================================
    //  circle list
    //      丸いサムネありリスト
    // ========================================
    &--circle {
        @extend #{$this};

        #{$this}__item {
            font-size: 12px;
        }
    }
}





// ========================================
// - gallery list
//========================================
.gallery-list {
    overflow: hidden;

    &__item {
        width: 33.33333333333%;
        float: left;
        padding: 0 5px;
        margin-bottom: 10px;

        &:nth-child(3n+1) {
            clear: both;
        }

        &-inner {
            overflow: hidden;
            position: relative;
            padding-top: 100%;
        }
    }

    &__link {
        @include center();
        width: 150%;
        height: 150%;
        color: #000;

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            max-height: 100%;
            max-width: 100%;
            height: auto;
            margin: auto;
            opacity: .6;
        }
    }

    &__date {
        @include center();
        width: 100%;
        text-align: center;
    }

    &__detail-wrapper {
        display: none;
    }
}

.gallery__detail-body {
    text-align: left;
}


.white-popup {
  position: relative;
  background: $C_SUB1_NOT;
  padding: 20px 40px;
  width: auto;
  max-width: 680px;
  margin: 10px auto;
  text-align: center;
}



.mfp-content {
    &:before {
        @include after();
        position: absolute;
        top: 10px;
        left: 50%;
        right: 0;
        bottom: 10px;
        background-color: $C_SUB1_NOT;
    }
}





// ========================================
// - category list
//========================================
.category-wrapper {
    @include mq-pc {
        display: flex;
        margin-left: 74px;
        margin-bottom: 20px;
    }
}

.category {
    &-list {
        margin-left: -5px;
        margin-right: -5px;
    }

    &__term {
        @extend %FONT_ENG;
        
        @include mq-pc {
            padding: 10px 0;
        }

        @include mq-mobile {
            margin-right: 20px;
        }

        &:after {
            @include mq-pc {
                content: "-";
                margin: 0 10px;
            }
        }
    }

    &-item {
        display: inline-block;

        &.active {
            position: relative;

            &:before {
                @include after();
                @include center();
                position: absolute;
                width: 90%;
                height: 20px;
                background-color: $C_SUB1_NOT;
            }
        }
    }

    &-link {
        position: relative;
        display: block;
        padding: 10px;
        color: #000;
    }
}