.download {
    .download-links {
        display: flex;

        @include mq-mobile {
            display: block;
            a.btn-30{
                width: 80%;
                margin: 10px auto;
            }
        }
    }
}
