/* ========================================

    common
        共通に使えるパーツ

    INDEX
        - wrapper
        - table
        - hr

======================================== */

/* ========================================
    wrapper
======================================== */
.wrapper {

}

.inner {

}

.link {
    color: $C_BLUE;
}



/* ========================================
    table
======================================== */

table {
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
}

th,td { 
    border: 1px solid black;
    text-align: left;
    padding: 7px;
}


th.w_30 {
    width: 30%;
}

th.w_45 {
    width: 45%;
}

.table-title {
    background: url("/images/common/bg/stripe_blue.png"); 
    color: black;
    font-weight: bold;
    vertical-align: middle;
}

.table-row td {
    color: black;
    background-color: $C_SUB3_bg;
}

.logged-in {
    .table-title {
        background: url("/images/common/bg/stripe_yellow.png");
    }
    .table-row td {
        background-color: $C_SUB1;
    }

}


/* ========================================
    hr
======================================== */

hr {
    &.dot {
        border: none;
        border-top: 7px dotted black;
    }
    &.border {
        border: none;
        padding: 3px;
        border-top: 1px solid $C_SUB3;
        border-bottom: 1px solid $C_SUB3;
    }

    &.stripe {
        border: none;
        background: url("/images/common/bg/stripe_blue.png") bottom repeat-x;
        height: 10px;
    }
}

.logged-in {
    hr {
        
        &.border {
            border: none;
            padding: 3px;
            border-top: 1px solid $C_SUB1;
            border-bottom: 1px solid $C_SUB1;
        }
    
        &.stripe {
            border: none;
            background: url("/images/common/bg/stripe_yellow.png") bottom repeat-x;
            height: 4px;
        }
    }

}