.memberscard {
	@include mq-mobile {
		margin-right: -10px;
		margin-left: -10px;
	}
}

.members-card {
	position: relative;
	width: 306px;
	margin-bottom: 40px;
	margin-right: auto;
	margin-left: auto;

	&__data {
		@include center();
		@extend %FONT_ENG_MODERN;
		margin-top: -40px;
		text-align: center;
		font-size: 1.5rem;
		line-height: 1.1em;	
	}

	&__qr {
		width: 124px;
		height: auto;
	}

	&__name {
		position: absolute;
		bottom: 60px;
		left: 0;
		right: 9px;
		width: 100%;
		height: 54px;
		text-align: center;

		&-text {
			@include center();
			width: 100%;
			padding: 0 20px;
		}
	}

	&__number {
		@extend %FONT_ENG_MODERN;
		position: absolute;
		top: auto;
		bottom: 25px;
		left: 0;
		width: 100%;
		text-align: center;
		font-size: 1rem;
	}
}