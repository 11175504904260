/* ========================================
    
    base
        タグに直接効かせるスタイル

    INDEX
        - common
        - anti-aliasing
        - bg
        - form
        - dl
        - main title
        - not found

======================================== */

/* ========================================
    common
======================================== */
%_low_content {
    min-height: 400px;

    @include mq-mobile {
        min-height: 200px;
    }
}

* {
    box-sizing: border-box;
}

body {
    font-family: $MAIN_FONT_FAMILY;
    font-size: 83%;
    line-height: 1.75;
    font-feature-settings: "pkna";
}


img {
    vertical-align: bottom;
    max-width: 100%;
    height: auto;
}

a {
    color: $C_SUB3;
    outline: none;
    text-decoration: none;
    // 以下のコメントアウトを外せば、スマホでタップした時のハイライトを消すことができます。
    // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    // tap-highlight-color: rgba(0, 0, 0, 0);
}

time {
    @extend %FONT_ENG_MODERN;
}


@mixin container() {
    min-width: 1000px;
}

.inner {
    position: relative;
    max-width: 750px;

    .container {
        @include mq-pc() {
            min-width: 100%;
            max-width: 100%;
        }
    }
}


.container {
    position: relative;
    width: 1000px;
    margin-right: auto;
    margin-left: auto;
    word-break: break-all;

    @include mq-pc() {
        @include container();
    }

    @include mq-mobile {
        width: 100%;
    }
}



.content-wrap {
    max-width: 1000px;
    width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 48px;

    @include mq-mobile {
        min-height: 60vh;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }

    &--full {
        max-width: none;
        width: 100%;

        .inner {
            max-width: none;
            margin-right: auto;
            margin-left: auto;
                
            @include mq-pc {
                width: 1000px;
            }
        }
    }
}

.section-title {
    @extend %FONT_ENG_MODERN;
    font-size: 3em;
}

.container--thin,
.thin-column {
    max-width: 680px;
}

.attention {
    color: $C_RED;
}

.low-content-wrapper {
    @extend %_low_content;
}

.no-result {
    @extend %FONT_ENG;
    text-align: center;
    font-size: 2em;

    @include mq-pc {
        max-width: 666px;
        min-height: 240px;
        
    }
}




/* ========================================
    anti-aliasing
======================================== */
// 文字のアンチエイリアス
// http://creator.dwango.co.jp/14128.html
// http://qiita.com/htomine/items/3086f30ca09adea2f8e0
// フォントレンダリング設定: 1x解像度ではsubpixel、2x以上の解像度ではgrayscale

body,
html {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: unset;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    body,
    html {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

body {
    @include mq-pc() {
        @include container();
    }
}




// ========================================
// - bg
//========================================
.bg-stripe--pink {
    background-image: url("/images/common/bg/stripe_pink.png");
}


.bg-stripe--yellow {
    background-image: url("/images/common/bg/stripe_yellow.png");
}


/* ========================================
    form
======================================== */
// スマホ、タブレットのformの見た目を整える
input,
textarea,
select {
    @include mq-mobile() {
        font-size: 16px;
    }
}

input[src$="btn_docomo_dlogin.gif"] {
    max-width: 195px;
}

textarea,
input[type="text"],
.textarea {
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 10px;
    max-width: 500px;
    width: 100%;
}

select {
    padding: 5px;
}


input[type="submit"],
input[type="button"] {
    appearance: none;
    border-radius: 0;
    border: none;
    box-sizing: border-box;
}

// 以下のコメントアウトを外すとiOSのデフォルトデザインが消えます。

// input[type="submit"],
// input[type="reset"],
// input[type="button"],
// input[type="text"],
// input[type="email"],
// textarea,
// select {
//     appearance: none;
// }
//
// input[type="text"],
// input[type="email"],
// input[type="submit"],
// input[type="reset"],
// input[type="button"],
// input[type="email"],
// textarea {
//     border-radius: 0;
//     border: none;
//     box-sizing: border-box;
//     background-color: transparent;
//
//     &:-webkit-search-decoration {
//         display: none;
//     }
//
//     &:focus {
//         outline-offset: -2px;
//     }
// }





// ========================================
// - dl
//========================================
dl {
    dt {
        margin-bottom: 20px;
        font-weight: bold;
        //font-size: 1.5em;
        line-height: 1.25em;
        border-bottom: 1px solid #000;
    }

    dd {
        margin-bottom: 40px;
    }
}

.txt-list02 {
    dd {
        margin-bottom: 0.5em;
    }
}






// ========================================
// - not found
//========================================

.body--notfound {
    background: $C_SUB1_NOT url("/images/common/bg/stripe_blue_large.png");

    @include mq-mobile {
        background-size: 50px;
    }

    &.logged-in {
        background-color: $C_SUB2;
    }
}

.not-found {
    &-wrapper {
        @extend %_low_content;
    }

    &__title {
        position: relative;
        height: 100px;
        margin-bottom: 40px;

        &:before {
            @include after();
            left: 0;
            top: 50%;
            height: 1px;
            width: 100%;
            background-color: #000;
        }

        .link-to-list {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
