.article {
	&__title {
		padding-bottom: 15px;
		padding-right: 2em;
		margin-bottom: 35px;
		font-size: 1.25em;
		border-bottom: 1px solid #000;
	}

	&__time {
		font-size: 1.25em;
	}

	&__body {
		margin-bottom: 40px;
		letter-spacing: .1em;
		line-height: 2em;
	}
}
