/* ========================================
    機能
        アコーディオンとして使う際に最低限必要な機能
======================================== */
.js-accordion-title {
    cursor: pointer;
}

.js-accordion-body {
    display: none;

    &.open {
        display: block;
    }
}


/* ========================================
    デザイン
        見た目の関するところ
======================================== */
.ac-menu {
    // タイトル
    dt {
		position: relative;
        background: url("/images/common/bg/stripe_blue_trans.png");
        padding: 15px;
        max-width: 700px;
		border-bottom: none;

        &:not(:nth-of-type(1)) {
            margin-top: 10px;
        }
		
		//矢印の背景
		&:before {
			content: "";
			position: absolute;
			right: -10px;
			bottom: -10px;
			width: 35px;
			height: 35px;
			background-color: black;
		}

		// 右隅の三角
        &:after {
            content: "";
            position: absolute;
			right: 1px;
			bottom: -3px;
            margin: auto;
            width: 0;
            height: 0;
            border-top: 7px solid $C_SUB1_NOT;
			border-right: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-left: 6px solid transparent;
		}

        // 開いている時
        &.js-open,
        &.fixed-open {
            border-bottom: none;

            &:after {
                content: "";
				position: absolute;
				right: 1px;
				bottom: 5px;
				margin: auto;
				width: 0;
				height: 0;
				border-top: 6px solid transparent;
				border-right: 6px solid transparent;
				border-bottom: 7px solid $C_SUB1_NOT;
				border-left: 6px solid transparent;
            }
        }
    }

    // 本文
    dd {
		margin-top: -20px;
	    padding: 20px 15px;
        background-color: $C_SUB1_NOT;
        max-width: 700px;

        &.js-open {
            display: block;
        }
    }
}

.logged-in {
    .ac-menu {
        // タイトル
        dt {
            font-size: 1.25em;
            background: url("/images/common/bg/stripe_pink.png");
    
            // 右隅の三角
            &:after {
                border-top: 7px solid $C_SUB2;
            }
    
            // 開いている時
            &.js-open,
            &.fixed-open {
               
                &:after {
                    content: "";
                    border-top: 6px solid transparent;
                    border-bottom: 7px solid $C_SUB2;
                }
            }
        }
    
        // 本文
        dd {
            background-color: $C_SUB2;
           
        }
    }

}