.regist-list-box {
	margin-bottom: 20px;

	li {
		list-style-type: decimal;
		padding-bottom: 5px;
		margin-bottom: 5px;
		border-bottom: 1px solid #ccc;
	}
}

.num_hd {
	margin-right: .25em;
	font-size: 1.5em;
	font-weight: bold;
}

.credit_faq {
	h3 {
		@extend .sub-title;
		font-weight: bold;
		font-size: 1.1rem;
	}
}